import {
  Decision,
  DecisionReason,
  DEFAULT_ON_READY_TIMEOUT,
  EventProcessor,
  FeatureFlagDecision,
  Logger,
  User,
  VariationKey,
  WorkspaceFetcher,
  HackleEvent,
  HackleInternalClient,
  Properties
} from "@hackler/sdk-core"
import { EventEmitter } from "events"
import HackleClient, { PageView } from "./HackleClient"
import { getUserId } from "./identifier/IdentifierManager"
import HacklePropertyGenerator from "./property/HacklePropertyGenerator"

const log = Logger.log

export default class HackleClientImpl implements HackleClient {
  private hackleInternalClient: HackleInternalClient

  constructor(workspaceFetcher: WorkspaceFetcher, eventProcessor: EventProcessor, eventEmitter: EventEmitter) {
    this.hackleInternalClient = new HackleInternalClient(workspaceFetcher, eventProcessor, eventEmitter)
  }

  variation(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): string {
    return this.variationDetail(experimentKey, user, defaultVariation).variation
  }

  variationDetail(experimentKey: number, user?: User | string, defaultVariation?: VariationKey): Decision {
    try {
      return this.hackleInternalClient._experiment(
        experimentKey,
        this._getUser(user),
        this._getHackleProperties(),
        defaultVariation || "A"
      )
    } catch (e) {
      log.error(
        `Unexpected exception while deciding variation for experiment[${experimentKey}]. Returning default variation[${defaultVariation}] : ${e}`
      )
      return Decision.of(defaultVariation || "A", DecisionReason.EXCEPTION)
    }
  }

  isFeatureOn(featureKey: number, user?: User | string): boolean {
    return this.featureFlagDetail(featureKey, user).isOn
  }

  featureFlagDetail(featureKey: number, user?: User | string): FeatureFlagDecision {
    try {
      return this.hackleInternalClient._featureFlag(featureKey, this._getUser(user), this._getHackleProperties())
    } catch (e) {
      log.error(`"Unexpected exception while deciding feature flag[${featureKey}]. Returning default value[false] : ${e}`)
      return FeatureFlagDecision.off(DecisionReason.EXCEPTION)
    }
  }

  track(event: HackleEvent | string, user?: User | string) {
    log.debug(`track event : ${JSON.stringify(event)}`)
    this.hackleInternalClient._track(this._convertEvent(event), this._getUser(user), this._getHackleProperties())
  }

  trackPageView(option?: PageView): void {
    log.debug("tracking page view")
    this.hackleInternalClient._track({ key: "$page_view" }, this._getUser(option?.user), this._getHackleProperties())
  }

  _getHackleProperties(): Properties {
    let hackleProperties
    // @ts-ignore
    if (typeof window !== "undefined") {
      // @ts-ignore
      hackleProperties = HacklePropertyGenerator.generate(window)
    }
    return hackleProperties || {}
  }

  _getUser(user?: User | string): User {
    if (user) {
      if (typeof user === "string") {
        return { id: user }
      } else {
        return user
      }
    }

    return {
      id: getUserId()
    }
  }

  onReady(block: () => void, timeout: number = DEFAULT_ON_READY_TIMEOUT): void {
    this.hackleInternalClient._onReady(block, timeout)
  }

  close(): void {
    log.debug("Hackle Client is closing")
    this.hackleInternalClient._close()
  }

  private _convertEvent(event: HackleEvent | string): HackleEvent {
    if (typeof event === "string") {
      return { key: event }
    }
    return event
  }
}
